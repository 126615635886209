<template>
  <!-- popup_wrap -->
  <div
    class="popup_wrap bg_white"
    style="width:420px; height:370px;"
  >
    <button
      type="button"
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <!-- popup_cont -->
    <div class="popup_cont">
      <div
        class="circle_icon notice"
        style="margin:30px auto 0;"
      />

      <p class="msg text_center color_blue font_16 font_medium mt15">
        <span>
          <!-- 선택하신 COMMODITY에 대한 <br>
          PROPER LASHING METHOD가 존재하지 않습니다. -->
          {{ $t('msg.NEWB010P140.006') }}
        </span>
      </p>

      <p class="msg text_center mt15">
        <span>
          <!-- 따라서 부킹 접수 시 발송되는 특수화물 부킹 승인 안내<br>
          메일 참고하시어 라싱작업 후 사진 업로드 부탁드립니다.<br>
          유관부서 확인 후 승인 혹은 거절되며<br>
          거절사유와 함깨 라싱 보완요청드릴 예정입니다. -->
          {{ $t('msg.NEWB010P140.007') }}<br>
          {{ $t('msg.NEWB010P140.008') }}
        </span>
      </p>

      <div
        class="mt20 text_center"
      >
        <a
          class="button blue md"
          href="#"
          @click.prevent="lashingDownload()"
        >
          <span class="btn_icon download" />
          DOWNLOAD
        </a><!-- DOWNLOAD -->
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import trans from '@/api/rest/trans/trans'

export default {
  name: 'OogLashingPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return { kmdVslCheck: 'N' }
      }
    }
  },
  data () {
    return {
      // 고정값
      fileInfo: {
        fileNm: 'PROPER LASHING METHOD (파일다운).xlsx',
        reqRno: '000001288133',
        atchFileSeq: '1',
        type: 'DG_OOG_CERTI_BK'
      }
    }
  },
  methods: {
    lashingDownload () {
      const fileName = 'GUIDE_TO_PROPER_LASHING_METHOD.pdf'

      trans.lashingPdfDownload(fileName)
        .then(response => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //IE에서 동작
            window.navigator.msSaveOrOpenBlob(response.data, fileName)
          } else {
            // console.log(response)
            //CHROME에서 동작
            let blob = new Blob([response.data], { type: response.headers['content-type'] })
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')

            link.href = url
            link.setAttribute('download', fileName)
            link.click()
            link.remove()

            window.URL.revokeObjectURL(url)
          }
        })
    }
  }
}
</script>
