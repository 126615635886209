var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap bg_white",
      staticStyle: { width: "420px", height: "370px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("div", {
          staticClass: "circle_icon notice",
          staticStyle: { margin: "30px auto 0" },
        }),
        _c(
          "p",
          {
            staticClass: "msg text_center color_blue font_16 font_medium mt15",
          },
          [
            _c("span", [
              _vm._v(" " + _vm._s(_vm.$t("msg.NEWB010P140.006")) + " "),
            ]),
          ]
        ),
        _c("p", { staticClass: "msg text_center mt15" }, [
          _c("span", [
            _vm._v(" " + _vm._s(_vm.$t("msg.NEWB010P140.007"))),
            _c("br"),
            _vm._v(" " + _vm._s(_vm.$t("msg.NEWB010P140.008")) + " "),
          ]),
        ]),
        _c("div", { staticClass: "mt20 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue md",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.lashingDownload()
                },
              },
            },
            [
              _c("span", { staticClass: "btn_icon download" }),
              _vm._v(" DOWNLOAD "),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }